
export const Env = {
    debug: process.env.NODE_ENV === 'production' ? false : true,
    ambience: process.env.NODE_ENV  === 'production' ? 'prod' : 'dev',
    api_url: process.env.NODE_ENV  === 'production'? 'https://api.geracaoemprego.ro.gov.br' : 'https://dev-api.crafty.work',
    api_club_url: process.env.NODE_ENV === 'production' ? 'https://api-club.crafty.work' : 'https://apidev-club.crafty.work',
    api_bluetrix :  process.env.NODE_ENV === 'production' ? 'https://api-white-label.bluetrix.com.br' : 'https://dev-api-white-label.bluetrix.com.br',
    header: {
        'X-Client-Device': 'web',
        Accept: 'application/json',
        'X-Version-Name': '2.0.0',
        'Content-Type': 'application/json',
        'X-App-Package': 'br.com.bluetrix.geracaoemprego.web'
    },
    header_club: {
        Accept: 'application/json',
        'Content-Type': "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "X-App-Alias": 'geracaoemprego'
    },
    configs:{},
    TRACKING_ID: 'G-TX7BRMMGE7',
    services_available: ['sine'], // 'crafty', 'sine', 'club'
    phone_validation: false,
    version: '2.5.1',
    application_title: 'Geração Emprego',
    application_name: 'br.com.bluetrix.geracaoemprego.web',
    application_alias: 'geracaoemprego',
    state_restriction: 'RO',
    state_restriction_full: "Rondônia",
    state_full: "Rondônia",
    support_company: 'SEDEC',
    citizen_name: 'Rondoniense',
    support_number: '',
    support_email: 'suporte@geracaoemprego.ro.gov.br',
    appointment_id: '11',
    show_client_panel: false,
    has_gov_br: false,

    event_name: "mesdamulher",
    event_limit_date: '2024-03-30',
    event_start_date: '2024-03-06',

    worker_play: 'https://play.google.com/store/apps/details?id=br.com.bluetrix.geracaoemprego',
    worker_apple: 'https://apps.apple.com/br/app/gera%C3%A7%C3%A3o-emprego-p-trabalhador/id1583838255',
    client_play: 'https://play.google.com/store/apps/details?id=br.com.bluetrix.geracaoemprego.hirer',
    client_apple: 'https://apps.apple.com/br/app/gera%C3%A7%C3%A3o-emprego/id1579210295',
    number: '+556932165172',

    biz_apple_store: 'https://apps.apple.com/br/app/crafty-biz-para-lojistas/id1444677721',
    biz_play_store: 'https://play.google.com/store/apps/details?id=br.com.crafty.biz',
    instagram: 'https://www.instagram.com/geracaoemprego.oficial/',
    facebook: 'https://www.facebook.com/GeracaoEmprego.Oficial',
};
