import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Route, Switch, withRouter} from "react-router-dom";
import DefaultLayout from './DefaultLayout';
import HomePage from "../../Pages/HomePage";
import HireServicesPage from "../../Pages/HireServicesPage";
import ServicePage from "../../Pages/ServicePage";
import {PrivateRoute} from "../../Helpers/PrivateRoute";
import useAuth from "../../Hooks/Auth";
import WorkHomePage from "../../Pages/WorkHomePage";
import {useDispatch, useSelector} from "react-redux";
import useLocalStorage from "../../Hooks/Storage";
import useApi from "../../Hooks/Api";
import {Button, notification} from "antd";
import PrivacyPage from "../../Pages/PrivacyPage";
import PasswordRecovery from "../../Pages/PasswordRecovery";
import SearchResumePage from "../../Pages/SearchResumePage";
import EstablishmentPage from "../../Pages/EstablishmentPage";
import SearchEstablishmentPage from "../../Pages/SearchEstablishmentPage";
import HowItWorksPage from "../../Pages/HowItWorksPage";
import useVerifyServices from "../../Hooks/VerifyServices";
import FullResumePage from "../../Pages/FullResumePage";
import {Images} from "../../Helpers/Images";
import ClientPanelPage from "../../Pages/ClientPanelPage";
import MyRecruitmentsPage from "../PageComponents/Restrict/ClientPanelPage/MyRecruitmentsPage";
import NotificationPage from "../PageComponents/Restrict/ClientPanelPage/NotificationPage";
import WorkerPanelPage from "../../Pages/WorkerPanelPage";
import {Env} from "../../Helpers/Env";
import PanelPage from "../../Pages/PanelPage";
import {StorageVariables} from "../../Helpers/StoragesVariables";
import LoginPageOld from "../../Pages/LoginPage";
import * as Sentry from "@sentry/react";
import {SearchOpportunityPage} from "../../Pages/SearchOpportunity/SearchOpportunityPage";
import {SearchCoursesPage} from "../../Pages/SearchCoursesPage";
import PDFReader from "../../Pages/PDFReader";
import ExternalAccessLayoutManager from "../../Pages/ExternallyAccess/ExternalAccessLayoutManager";
import CommonQuestions from "../../Pages/CommonQuestions";
import CompanyProfile from "../../Pages/CompanyProfile";
import {LandingPage} from "../../Pages/LandingPage/LandingPage";
import GovAccessConfigPage from "../../Pages/GovAccessConfigPage";
import LogoutScreen from "../../Pages/LogoutScreen";
import Loading from "../Generic/Loading";
import Companies from "../../Pages/Companies"
import ReactGA from 'react-ga4'
import {routesArray} from "../../routes/routes";
import {debounce} from 'lodash'
import useEvents from "../../Helpers/Events";
import RemoveAccount from "../Survey/RemoveAccount";

import {eventVisible} from "../../Hooks/Helpers";

const LayoutManager = props => {
    const pathnameChanged = window.location.pathname[window.location.pathname.length -1 ] === '/' ? window.location.pathname.substring(0, window.location.pathname.length -1) : window.location.pathname
    const {isLogged, getUser, store, storeSineProfile} = useAuth();
    const dispatch = useDispatch();
    const profile = useSelector(state => state).authReducer;
    const request = useSelector(state => state).pushNotificationRequestReducer;
    const {hasSineService, hasCraftyService, hasClubService} = useVerifyServices();
    const userCookiesPermission = useLocalStorage(StorageVariables.cookies);
    const [, setButtonCookieClicked] = useState(false);
    const fcmStorage = useLocalStorage(StorageVariables.fcm);
    const hasProfileStorage = useLocalStorage(StorageVariables.hasSineProfile);
    const api = useApi();
    const envApi = useApi({headers:{...Env.header, 'X-App-Package': Env.application_name}});
    const [loading, setLoading] = useState(true)
    const {gaEvents} = useEvents()

    const getEnv = async () => {
        let aux = {}

        envApi.get('user/application', (res) => {
            Object.keys(res).forEach((item) => {
                if (item !== 'additional_configs') {
                    aux[item] = res[item]
                }
            })

            Env.configs = {...res?.additional_configs, ...aux};
            Env.number = `+${res.contact_number}`
            setLoading(false)
        }, () => {
            setLoading(false)
        })
    }

    const configNotifications = () => {
        if ("Notification" in window) {
            Notification?.requestPermission()?.then((permission) => {
                if (permission === 'granted') {
                    window.getMessagingTokenService((currentToken) => {
                        if (currentToken) {
                            if (fcmStorage.getPrimitive() !== currentToken) {
                                api.post('notifications/register', {
                                    fcm_token: currentToken,
                                    application_name: Env.application_name
                                }, (res) => {
                                }, (e) => {
                                })
                            }
                            fcmStorage.setPrimitive(currentToken);
                            window.onMessageService((payload) => {
                                if (payload.data["crafty.fcm_type"] === "chat") {
                                    dispatch({
                                        type: 'new_chat_notification',
                                        data: {chat_id: payload.data["crafty.chat_id"]}
                                    });
                                } else {
                                    dispatch({type: 'add_notification'});
                                    const key = 'notificationGlobalLocalKey';
                                    notification.open({
                                        duration: 7,
                                        description: <div style={{display: 'flex', marginTop: -20}}>
                                            <div style={{paddingRight: 5}}><img style={{height: 40}}
                                                                                src={Images.logo_icon}/></div>
                                            <div style={{display: 'flex', flexFlow: 'column'}}>
                                                <strong style={{fontSize: 16}}> Notificação </strong>
                                                <span> {payload.notification?.body || 'Você recebeu uma nova notificação'} </span>
                                            </div>
                                        </div>,
                                        key,
                                        placement: 'topRight'
                                    });
                                }
                            });
                            dispatch({type: 'enabled_push', data: true})
                        } else {
                            console.log('No Instance ID token available. Request permission to generate one.');
                        }
                    })
                } else {
                    console.log('Unable to get permission to notify.');
                }
            });
        }
    };

    const getNotifications = async () => {
        api.get('notifications/count', (res) => {
            dispatch({type: 'init_count', data: res.count});
        }, (e) => {
            console.log(e);
        });
    };


    const handleCookiesAccept  = () => {
        userCookiesPermission.setPrimitive(true);
        setButtonCookieClicked(true);
    };

    const checkDataUser = (userObj) => {
        setLoading(true)
        api.get(`user/${userObj.user_id}/full`, (user) => {
            store(user); // Atualiza agora com oq tem na api
            api.get(`sine/user/${userObj.user_id}/full`, (sine) => {
                if(sine.user_id !== null) {
                    hasProfileStorage.setPrimitive(true)
                } else {
                    hasProfileStorage.setPrimitive(false);
                }
                storeSineProfile(sine);
                setLoading(false);
            }, ()=> {
                setLoading(false)
            })
        }, (err)=> {
            setLoading(false)
        })
    }

    useMemo(() => {
        const userObj = getUser();

        if(userObj?.token) {
            store(userObj, true); // Ja inicializa o redux com oq tem no storage
            checkDataUser(userObj);
            getNotifications();
        }
        if (window.firebase?.messaging?.isSupported()) {
            try {
                window.firebase?.messaging()?.usePublicVapidKey("BGJAZkzCLMZM-TwiPg48xrLY4YwwASFOSnxV7FI4z3fE_l2p8Tar9WiaRU5XDN_QxZctuj-2bzA_L0rxHs9BCb8");
            } catch (e) {
                console.log(e)
            }
        }
    }, []);

    useEffect(() => {
        if(profile !== null) {
            configNotifications();
        }
    },[profile, request]);

    const sendToGoogle = useCallback(debounce(()=> {
        gaEvents.sendPath()

        Sentry.addBreadcrumb({
            category: "active_screen",
            message: window.location.pathname,
        });

    }, 1500),[])

    useEffect(() => {
        getEnv()
    }, []);

    useEffect(() => {
        sendToGoogle()
    },[pathnameChanged]);

    if(loading) return <div style={{height: window.innerHeight, display:'flex'}}><Loading/></div>

    return (
        <Switch>
            <Route path={'/acesso-externo/:path'}>
                <ExternalAccessLayoutManager/>
            </Route>
            <Route path={'/acesso-gov'} component={GovAccessConfigPage}/>
            <Route path={'/remover-acesso-gov'} component={LogoutScreen}/>
            <Route>
                <DefaultLayout>
                    <Route exact path={'/'} component={isLogged()? PanelPage : HomePage}/>
                    <PrivateRoute exact path={'/painel'} component={PanelPage}/>
                    <PrivateRoute path={'/painel/prestador'} component={WorkerPanelPage}/>
                    <PrivateRoute path={'/painel/cliente'} component={ClientPanelPage}/>

                    <Route exact path={'/como-funciona/:type'} component={HowItWorksPage}/>
                    <Route exact path={'/termos'} component={PrivacyPage}/>
                    <Route exact path={'/politica-de-privacidade'} component={PrivacyPage}/>
                    <Route exact path={'/redefinir-senha/:token'} component={PasswordRecovery}/>
                    <Route exact path={'/trabalhar'} component={isLogged()? PanelPage : WorkHomePage}/>
                    <Route exact path={'/recuperar-senha'} component={LoginPageOld}/>
                    <Route exact path={'/empresa/:id'} component={CompanyProfile}/>
                    {/*<Route exact path={'/empresa/'} component={LandingPage}/>*/}
                    <Route exact path={'/empresas/'} component={Companies}/>
                    <Route exact path={'/pdf/cursodecapacitacao'} component={PDFReader}/>
                    <Route exact path={'/cursos'} component={PDFReader}/>
                    <Route exact path={'/editais/:id?'} component={PDFReader}/>
                    <Route exact path={'/empresa/'} component={LandingPage}/>
                    <Route exact path={'/remover-conta'} component={RemoveAccount}/>


                    {hasSineService() && <Route exact path={'/buscar-vagas'} component={SearchOpportunityPage}/>}
                    {hasSineService() && <Route exact path={'/vagas-de-emprego'} component={SearchOpportunityPage}/>}
                    {(hasSineService() && eventVisible()) && <Route path={`/vagas-do-evento`} component={SearchOpportunityPage} />}
                    {(hasSineService() && eventVisible()) && <Route path={`/cursos-do-evento`} component={SearchCoursesPage} />}
                    {hasSineService() && <Route exact path={'/buscar-cursos'} component={SearchCoursesPage}/>}

                    {hasCraftyService() && <Route exact path={'/quero-contratar'} component={HireServicesPage}/>}
                    {hasCraftyService() && <Route exact path={'/prestador-de-servico/:id/:occupation'} component={ServicePage}/>}

                    {hasClubService() && <Route exact path={'/estabelecimentos'} component={SearchEstablishmentPage}/>}
                    {hasClubService() && <Route exact path={'/estabelecimento/:id'} component={EstablishmentPage}/>}

                    {hasSineService() && <Route exact path={'/curriculos'} component={SearchResumePage}/>}
                    {hasSineService() && <PrivateRoute exact path={'/recrutamentos/:type'} component={MyRecruitmentsPage}/>}
                    {hasSineService() && <PrivateRoute exact path={'/curriculo/privado/:id'} component={FullResumePage}/>}
                    <PrivateRoute exact path={'/notificacoes'} component={NotificationPage}/>

                    <Route path={'/perguntas-frequentes'} component={CommonQuestions}/>
                    {/*<Redirect to={'/'}/>*/}
                </DefaultLayout>
            </Route>

            {!userCookiesPermission.getPrimitive() && <div className='fixedNotifications'>
                <div className={'cookiesMessage'}>
                    <span style={{marginRight: '10px', paddingTop: '7px',fontSize: '13px'}}>Nós utilizamos cookies para garantir que forneceremos a melhor experiência em nosso site.</span>
                    <div>
                        <Button style={{marginRight: 10}} type={'primary'} onClick={() => handleCookiesAccept()}> Aceitar </Button>
                        <Button className={'ant-btn-red'} href={'https://www.google.com.br'}> Eu Discordo </Button>
                    </div>
                </div>
            </div>}

        </Switch>
    )

};

LayoutManager.propTypes = {};

export default withRouter(LayoutManager);
