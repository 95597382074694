import React from 'react';
import edital1 from '../ApplicationAssets/editaln5.pdf'
import edital7 from '../ApplicationAssets/edital7.pdf'
import edital8 from '../ApplicationAssets/edital8.pdf'
import edital9 from '../ApplicationAssets/edital9.pdf'
import edital15 from '../ApplicationAssets/edital15.pdf'
import edital10 from '../ApplicationAssets/edital10.pdf'
import edital11 from '../ApplicationAssets/edital11.pdf'
import edital12 from '../ApplicationAssets/edital12.pdf'
import edital13 from '../ApplicationAssets/ERRATA 10.05.2022.pdf'
import edital5fim from '../ApplicationAssets/editaln5fim.pdf'
import renderPdf from '../ApplicationAssets/Novo_edital_sei.pdf'
import renderAdditionalPdf from '../ApplicationAssets/edital_complementar.pdf'
import renderErrataPdf from '../ApplicationAssets/errata.pdf'
import renderChamado from '../ApplicationAssets/chamada.pdf'
import renderPreliminar from '../ApplicationAssets/preliminar.pdf'
import renderFinalPreliminar from '../ApplicationAssets/Edital FINAL PRELIMINAR.pdf'
import renderFinal from '../ApplicationAssets/Resultado Final.pdf'
import edital14 from '../ApplicationAssets/edital-21-2021-rfp.pdf'
import edital15Aprovados from '../ApplicationAssets/edital15-aprovados.pdf'
import edital19 from '../ApplicationAssets/edital19.pdf'
import edital2020221 from '../ApplicationAssets/edital-20-2022-1.pdf'
import editalrf202022  from '../ApplicationAssets/rf-20-2022.pdf'
import edital212021inf  from '../ApplicationAssets/21-2021-sedi-sine-informativo.pdf'
import edital062023  from '../ApplicationAssets/SEI_ABC - 0039416718 - Edital de Cursos.pdf'
import edital062023Prorrogado  from '../ApplicationAssets/0039865131.pdf'
import editalAprovados062023  from '../ApplicationAssets/edital 8 - Aprovados Preliminarmente e Convocação.pdf'
import editalFinal623  from '../ApplicationAssets/ResultadoFinal.pdf'
import local62023  from '../ApplicationAssets/local62023.pdf'
import edital112023 from '../ApplicationAssets/EDITAL 11.2023.SEDEC.SINE..pdf'
import edital122023 from '../ApplicationAssets/edital 12.2023.SEDEC.SINE.pdf'
import edital132023 from '../ApplicationAssets/Edital 13.2023.SEDEC-SINE.pdf'
import informacaoEdital from '../ApplicationAssets/informacao11.pdf'
import resultado111213 from '../ApplicationAssets/resultado11-12-13.pdf'
import edital0124 from '../ApplicationAssets/edital01.24.pdf'
import edital0125 from '../ApplicationAssets/SEI_ABC0047037102.pdf'
import edital0125resultado from '../ApplicationAssets/resultadopreliminar012024.pdf'
import Resultadofinal0002 from '../ApplicationAssets/Resultadofinal0002.pdf'
import ResultadofinalCronograma from '../ApplicationAssets/0047352063cronograma.pdf'
import edital42024 from '../ApplicationAssets/edital42024.pdf'
import edital42024preliminar from '../ApplicationAssets/preliminar042024.pdf'
import edital42024final from '../ApplicationAssets/edital42024final.pdf'
import edital52024 from '../ApplicationAssets/edital052024.pdf'
import edital52024final from '../ApplicationAssets/edital52024final.pdf'
import edital42024retificado from '../ApplicationAssets/edital42024retificado.pdf'
import edital52024local from '../ApplicationAssets/edital52024local.pdf'
import edital92024 from '../ApplicationAssets/edital92024.pdf'
import edital92024alt from '../ApplicationAssets/edital09alt.pdf'
import edital092024final from '../ApplicationAssets/edital092024final.pdf'
import edital092024final2 from '../ApplicationAssets/resultadofinal09.pdf'

export const editais = {
    active:  {
        title: <>
            <div>
                A Secretaria de Estado do Desenvolvimento Econômico - SEDEC, no uso de suas atribuições legais, torna público, o processo seletivo para os cursos de capacitação profissional, conforme discriminados nos itens deste edital.
            </div>
            <div style={{textAlign: 'left'}}>
                <strong>Cursos - </strong>
                <span>

                    {[
                        "Arranjos Florais",
                        "Assistente de Tecnologia da Informação",
                        "Atualização em corte e escova",
                        "Churrasqueiro",
                        "Garçom",
                        "Manutenção de Drones",
                        "Operador de Drones",
                        "Panificação",
                        "Penteados",
                        "Pizzaiolo",
                        "Unhas em Gel",
                        "Vitrinista"
                    ].map((item, index) => <div key={index} style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{height: 8, width: 8, marginRight: 5, background: '#72b166', borderRadius: '50%'}}/> {item}
                    </div>)}

                    *Verificar no edital as cidades que cada curso contempla.
                    </span>
            </div>
            <div style={{marginTop: 20}}>
                <strong>Municípios - </strong>
                {["Porto Velho (Modalidade Presencial)",
                    ].map((item, index) => {
                    return (
                        <span key={index}>{item}; </span>
                    )
                })}
            </div>
            <div style={{marginTop:'10px'}}>
                <button className={'ant-btn ant-btn-blue-ghost' } onClick={() => window.open('https://geracaoemprego.ro.gov.br/buscar-cursos')}>
                    Para inscrição, acesse o link e escolha o curso desejado
                </button>
            </div>
        </>,
        subtitle: <span>Em andamento - Edital Nº 9/2024/ SEDEC-SINE</span>,
        route: '09-2024-sedec',
        editais: [
            {
                text: 'Edital de abertura (Edital Nº9/2024/ SEDEC-SINE)',
                link: edital92024
            }
            ,{
                text: 'Alteração das datas de inscrição.',
                link: edital92024alt
            },{
                text: 'Resultado Final e Prorrogação',
                link: edital092024final
            },{
                text: 'Resultado Final',
                link: edital092024final2
            }
        ],
    },
    ongoing: [
        {
            title: <>
                <div>
                    A Secretaria de Estado do Desenvolvimento Econômico - SEDEC, no uso de suas atribuições legais, torna público, o processo seletivo para os cursos de capacitação profissional, conforme discriminados nos itens deste edital.
                </div>
                <div style={{textAlign: 'left'}}>
                    <strong>Cursos - </strong>
                    <span>

                    {[
                        "Arranjos Florais",
                        "Assistente de Tecnologia da Informação",
                        "Atualização em corte e escova",
                        "Churrasqueiro",
                        "Garçom",
                        "Manutenção de Drones",
                        "Operador de Drones",
                        "Panificação",
                        "Penteados",
                        "Pizzaiolo",
                        "Unhas em Gel",
                        "Vitrinista"
                    ].map((item, index) => <div key={index} style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{height: 8, width: 8, marginRight: 5, background: '#72b166', borderRadius: '50%'}}/> {item}
                    </div>)}

                        *Verificar no edital as cidades que cada curso contempla.
                    </span>
                </div>
                <div style={{marginTop: 20}}>
                    <strong>Municípios - </strong>
                    {["Porto Velho (Modalidade Presencial)",
                    ].map((item, index) => {
                        return (
                            <span key={index}>{item}; </span>
                        )
                    })}
                </div>
                <div style={{marginTop:'10px'}}>
                    <button className={'ant-btn ant-btn-blue-ghost' } onClick={() => window.open('https://geracaoemprego.ro.gov.br/buscar-cursos')}>
                        Para inscrição, acesse o link e escolha o curso desejado
                    </button>
                </div>
            </>,
            subtitle: <span>Em andamento - Edital Nº 9/2024/ SEDEC-SINE</span>,
            route: '09-2024-sedec',
            editais: [
                {
                    text: 'Edital de abertura (Edital Nº9/2024/ SEDEC-SINE)',
                    link: edital92024
                },
                {
                    text: 'Alteração das datas de inscrição.',
                    link: edital92024alt
                },{
                    text: 'Resultado Final e Prorrogação',
                    link: edital092024final
                },{
                    text: 'Resultado Final',
                    link: edital092024final2
                }
            ],
        },
        {
            title: <>
                <div>
                    A Secretaria de Estado do Desenvolvimento Econômico - SEDEC, no uso de suas atribuições legais, torna público, o processo seletivo para os cursos de capacitação profissional, conforme discriminados nos itens deste edital.
                </div>
                <div style={{textAlign: 'left'}}>
                    <strong>Cursos - </strong>
                    <span>

                    {[
                        "Assistente Financeiro e de Tesouraria",
                        "Auxiliar Administrativo",
                        "Contabilidade Básica",
                        "Mediador de Conflitos",
                        "Porteiro",
                        "Promotor de Vendas",
                        "Recepcionista",
                        "Técnicas de Vendas",
                    ].map((item, index) => <div key={index} style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{height: 8, width: 8, marginRight: 5, background: '#72b166', borderRadius: '50%'}}/> {item}
                    </div>)}

                        *Verificar no edital as cidades que cada curso contempla.
                    </span>
                </div>
                <div style={{marginTop: 20}}>
                    <strong>Municípios - </strong>
                    {["Porto Velho (Modalidade Presencial)",
                    ].map((item, index) => {
                        return (
                            <span key={index}>{item}; </span>
                        )
                    })}
                </div>
                <div style={{marginTop:'10px'}}>
                    <button className={'ant-btn ant-btn-blue-ghost' } onClick={() => window.open('https://geracaoemprego.ro.gov.br/buscar-cursos')}>
                        Para inscrição, acesse o link e escolha o curso desejado
                    </button>
                </div>
            </>,
            subtitle: <span>Em andamento - EDITAL Nº 05/2024/ SEDEC-SINE</span>,
            route: '05-2024-sedec',
            editais: [
                {
                    text: 'Edital de abertura (Edital Nº5/2024/ SEDEC-SINE)',
                    link: edital52024
                },
                {
                    text: 'Resultado Final',
                    link: edital52024final
                },
                {
                    text: 'Data de início e local dos cursos',
                    link: edital52024local
                },
            ],
        },
        {
            title: <>
                <div>
                    A Secretaria de Estado do Desenvolvimento Econômico - SEDEC, no uso de suas atribuições legais, torna público, o processo seletivo para os cursos de capacitação profissional, conforme discriminados nos itens deste edital.
                </div>
                <div style={{textAlign: 'left'}}>
                    <strong>Cursos - </strong>
                    <span>

                    {[
                        "Açougueiro",
                        "Bolos e cupcakes",
                        "Doces Finos",
                        "Eletricista básico de automóveis",
                        "Manutenção de refrigeração",
                        "Manutenção de computadores",
                        "Manutenção de redes de computadores",
                        "Manutenção para ar condicionado",
                        "Mecânica e manutenção de motociclistas com injeção eletrônica",
                        "Mecânica e manutenção para ar condicionado automotivo",
                        "Mecânico de veículos leves",
                        "Mecânico e manutenção de máquinas agrícolas",
                        "Montador e reparador de computadores",
                        "Operador de empilhadeiras",
                        "Operador de retroescavadeira",
                        "Salgadeiro",
                    ].map((item, index) => <div key={index} style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{height: 8, width: 8, marginRight: 5, background: '#72b166', borderRadius: '50%'}}/> {item}
                    </div>)}

                        *Verificar no edital as cidades que cada curso contempla.
                    </span>
                </div>
                <div style={{marginTop: 20}}>
                    <strong>Municípios - </strong>
                    {["Alta Floresta",
                        "Ariquemes",
                        "Buritis",
                        "Cacoal",
                        "Cerejeiras",
                        "Espigão do Oeste",
                        "Jaru",
                        "Ji-Paraná",
                        "Porto Velho",
                        "Rolim de Moura",
                        "Santa Luzia",
                        "Vilhena"].map((item, index) => {
                        return (
                            <span key={index}>{item}; </span>
                        )
                    })}
                </div>
                <div style={{marginTop:'10px'}}>
                    <button className={'ant-btn ant-btn-blue-ghost' } onClick={() => window.open('https://geracaoemprego.ro.gov.br/buscar-cursos?id=650')}>
                        Clique aqui para se inscrever
                    </button>
                </div>
            </>,
            subtitle: <span>Em andamento - EDITAL Nº 04/2024/ SEDEC-SINE</span>,
            route: '04-2024-sedec',
            editais: [
                {
                    text: 'Edital de abertura (Edital Nº 04/2024/ SEDEC-SINE)',
                    link: edital42024
                },
                {
                    text: 'Resultado Preliminar',
                    link: edital42024preliminar
                },
                {
                    text: 'Resultado Final',
                    link: edital42024final
                },
                {
                    text: 'Informação - Data de início das turmas',
                    link: edital42024retificado
                },
            ],
        },
        {
            title: <>
                <div>
                    A Secretaria de Estado do Desenvolvimento Econômico - SEDEC, no uso de suas atribuições legais, torna público, o processo seletivo para os cursos de capacitação profissional, conforme discriminados nos itens deste edital.
                </div>
                <div style={{textAlign: 'left'}}>
                    <strong>Cursos - </strong>
                    <span>

                    {[
                        "Açougueiro",
                        "Bolos e cupcakes",
                        "Doces Finos",
                        "Eletricista básico de automóveis",
                        "Manutenção de refrigeração",
                        "Manutenção de computadores",
                        "Mecânica e manutenção de motociclistas com injeção eletrônica",
                        "Mecânica e manutenção para ar condicionado automotivo",
                        "Mecânico de veículos leves",
                        "Montador e reparador de computadores",
                        "Operador de empilhadeiras",
                        "Operador de retroescavadeira",
                        "Salgadeiro",
                    ].map((item, index) => <div key={index} style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{height: 8, width: 8, marginRight: 5, background: '#72b166', borderRadius: '50%'}}/> {item}
                    </div>)}


                    </span>
                </div>
                <div style={{marginTop: 20}}>
                    <strong>Municípios - </strong>
                    {["Ariquemes",
                        "Buritis",
                        "Cerejeiras",
                        "Nova Brasilândia",
                        "Ouro Preti do Oeste",
                        "Porto Velho",
                        "Theobroma",
                        "Vale do Anari",
                        "Vilhena"].map((item, index) => {
                            return (
                                <span key={index}>{item}; </span>
                            )
                    })}
                </div>
                <div style={{marginTop:'10px'}}>
                    <button className={'ant-btn ant-btn-blue-ghost' } onClick={() => window.open('https://geracaoemprego.ro.gov.br/buscar-cursos?id=649')}>
                        Clique aqui para se inscrever
                    </button>
                </div>
            </>,
            subtitle: <span>Em andamento - EDITAL Nº 01/2024/ SEDEC-SINE</span>,
            route: '01-2024-sedec',
            editais: [
                {
                    text: 'Edital de abertura (Edital Nº 01/2024/ SEDEC-SINE)',
                    link: edital0124
                },
                {
                    text: 'Informação - Horário de execução dos cursos',
                    link: edital0125
                },
                {
                    text: 'Resultado Preliminar',
                    link: edital0125resultado
                },{
                    text: 'Resultado Final',
                    link: Resultadofinal0002
                },
                {
                    text: 'CRONOGRAMA DE INÍCIO DAS TURMAS',
                    link: ResultadofinalCronograma
                },
            ],
        },


        {
            title: <>
                <div>
                    A Secretaria de Estado do Desenvolvimento Econômico - SEDEC, no uso de suas atribuições legais, torna público por meio deste Edital a ABERTURA DAS INSCRIÇÕES do PROCESSO SELETIVO, para cursos na modalidade presencial.
                </div>
                <div style={{textAlign: 'left'}}>
                    <strong>Cursos - </strong>
                    <span>

                    {[
                        "Eletricista básico de Automóveis",
                        "Mecânica e Manutenção de Motocicletas com Injeção Eletrônica",
                        "Informática Básica",
                    ].map((item, index) => <div key={index} style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{height: 8, width: 8, marginRight: 5, background: '#72b166', borderRadius: '50%'}}/> {item}
                    </div>)}


                    </span>
                </div>
                <div style={{marginTop: 20}}>
                    <strong>Municípios - </strong>
                    <span>
                        Vale do Paraíso
                    </span>
                </div>
                <div style={{marginTop:'10px'}}>
                    <button className={'ant-btn ant-btn-blue-ghost' } onClick={() => window.open('https://geracaoemprego.ro.gov.br/buscar-cursos/?id=609')}>
                        Clique aqui para se inscrever
                    </button>
                </div>
            </>,
            subtitle: <span>Em andamento - EDITAL 13/2023/ SEDEC-SINE</span>,
            route: '13-2023-sedec',
            editais: [
                {
                    text: 'Resultado e Cronograma',
                    link: resultado111213
                },
                {
                    text: 'Edital de abertura das inscrições (13/2023/SEDEC-SINE)',
                    link: edital132023
                },
                {
                    text: 'Informação',
                    link: informacaoEdital
                },
            ],
        },
        {
            title: <>
                <div>
                    A Secretaria de Estado do Desenvolvimento Econômico - SEDEC, no uso de suas atribuições legais, torna público por meio deste Edital a ABERTURA DAS INSCRIÇÕES do PROCESSO SELETIVO, para cursos na modalidade presencial.
                </div>
                <div style={{textAlign: 'left'}}>
                    <strong>Cursos - </strong>
                    <span>

                    {[
                        "Açougueiro",
                        "Corte e Costura",
                    ].map((item, index) => <div key={index} style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{height: 8, width: 8, marginRight: 5, background: '#72b166', borderRadius: '50%'}}/> {item}
                    </div>)}


                    </span>
                </div>
                <div style={{marginTop: 20}}>
                    <strong>Municípios - </strong>
                    <span>
                        Nova Brasilândia
                    </span>
                </div>
                <div style={{marginTop:'10px'}}>
                    <button className={'ant-btn ant-btn-blue-ghost' } onClick={() => window.open('https://geracaoemprego.ro.gov.br/buscar-cursos/?id=610')}>
                        Clique aqui para se inscrever
                    </button>
                </div>
            </>,
            subtitle: <span>Em andamento - EDITAL 12/2023/ SEDEC-SINE</span>,
            route: '12-2023-sedec',
            editais: [
                {
                    text: 'Resultado e Cronograma',
                    link: resultado111213
                },
                {
                    text: 'Edital de abertura das inscrições (12/2023/SEDEC-SINE)',
                    link: edital122023
                },
                {
                    text: 'Informação',
                    link: informacaoEdital
                },
            ],
        },
        {
            title: <>
                <div>
                    A Secretaria de Estado do Desenvolvimento Econômico - SEDEC, no uso de suas atribuições legais, torna público por meio deste Edital a ABERTURA DAS INSCRIÇÕES do PROCESSO SELETIVO, para cursos na modalidade presencial.
                </div>
                <div style={{textAlign: 'left'}}>
                    <strong>Cursos - </strong>
                    <span>

                    {[
                        "Informática Básica",
                        "Mecânica e Manutenção de Motocicletas com Injeção Eletrônica",
                        "Mecânico de Máquinas Industriais",
                        "Montador e Reparador de Computadores",
                        "Operador de Empilhadeira",
                    ].map((item, index) => <div key={index} style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{height: 8, width: 8, marginRight: 5, background: '#72b166', borderRadius: '50%'}}/> {item}
                    </div>)}


                    </span>
                </div>
                <div style={{marginTop: 20}}>
                    <strong>Municípios - </strong>
                    <span>
                        Campo Novo; Guajará-Mirim; Nova Mamoré;
                    </span>
                </div>
                <div style={{marginTop:'10px'}}>
                    <button className={'ant-btn ant-btn-blue-ghost' } onClick={() => window.open('https://geracaoemprego.ro.gov.br/buscar-cursos/?id=611')}>
                        Clique aqui para se inscrever
                    </button>
                </div>
            </>,
            subtitle: <span>Em andamento - EDITAL 11/2023/ SEDEC-SINE</span>,
            route: '11-2023-sedec',
            editais: [
                {
                    text: 'Resultado e Cronograma',
                    link: resultado111213
                },
                {
                    text: 'Edital de abertura das inscrições (11/2023/SEDEC-SINE)',
                    link: edital112023
                },
                {
                    text: 'Informação',
                    link: informacaoEdital
                },
            ],
        },

    ],
    completed: [
        {
            title: <>
                <div>
                    A Secretaria de Estado do Desenvolvimento Econômico - SEDEC, no uso de suas atribuições legais, torna público, o processo seletivo para os cursos de Capacitação profissional, conforme descriminados nos itens deste Edital.
                </div>
                <div style={{textAlign: 'left'}}>
                    <strong>Cursos - </strong>
                    <span>

                    {[
                        "Mecânico de Manutenção de ar-condicionado automotivo",
                        "Montador e reparador de computadores",
                        "Mecânica e Manutenção de motocicletas com injeção eletrônica",
                        "Operação em retroescavadeira",
                        "Manutenção de Computadores",
                        "Mecânica de Manutenção para ar-condicionado",
                        "Mecânico e Manutenção de refrigeração e climatização residencial",
                        "Eletricista básico de automóveis",
                        "Mecânica de Manutenção de máquinas industriais",
                        "Mecânico de manutenção de refrigeradores",
                        "Operação de Retroescavadeira",
                        "Manutenção de Computadores",
                        "Mecânica de Manutenção de máquinas agrícolas",
                        "Doces Finos",
                        "Salgadeiro",
                        "Bolos e cupcakes",
                        "Corte e costura",
                        "Informática Básica",
                        "Mecânica de Manutenção de veículos leves",
                        "Açougueiro",
                        "Informática Aplicada aos processos de escritórios",
                        "Operador de Retroescavadeira",
                        "Operador de Empilhadeira",
                        "Eletricista básico de automóveis"
                    ].map((item, index) => <div key={index} style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{height: 8, width: 8, marginRight: 5, background: '#72b166', borderRadius: '50%'}}/> {item}
                    </div>)}


                    </span>
                </div>
                <div style={{marginTop: 20}}>
                    <strong>Municípios - </strong>
                    <span>Porto Velho;
                        Ariquemes;
                        Jaru;
                        Ji-Paraná;
                        Mirante da Serra;
                        Alvorada do Oeste;
                        São Miguel do Guaporé;
                        São Francisco do Guaporé;
                        Seringueiras;
                        Costa Marques;
                        Nova Brasilândia;
                        Alta Floresta;
                        Santa Luzia do Oeste;
                        Rolim de Moura;
                        Cacoal;
                        Pimenta Bueno;
                        Vilhena;
                    </span>
                </div>
            </>,
            subtitle: <span>Encerrado - EDITAL Nº 6/2023/SEDEC-SINE </span>,
            route: '06-2023-sedec',
            editais: [
                {
                    text: 'Datas de início das turmas e locais das aulas',
                    link: local62023
                },
                {
                    text: 'Resultado Final',
                    link: editalFinal623
                },
                {
                    text: 'Resultado Preliminar e Convocação Regularização de Inscrição',
                    link: editalAprovados062023
                }   ,
                {
                    text: 'EDITAL Nº 7/2023/SEDEC-SINE',
                    link: edital062023Prorrogado
                },
                {
                    text: 'EDITAL Nº 6/2023/SEDEC-SINE',
                    link: edital062023
                }],
        },
        {
            title: `A Superintendência Estadual de Desenvolvimento Econômico e Infraestrutura - SEDEC, no uso de suas atribuições legais, torna público, o processo seletivo para os cursos de qualificação profissionais, conforme descriminados nos itens deste Edital.`,
            subtitle: `Encerrado - EDITAL Nº 21/2021/SEDI-SINE`,
            route: '21-2021-sedi',
            editais: [
                {
                    text: 'INFORMATIVO' ,
                    link: edital212021inf
                },
                {
                    text: 'RESULTADO FINAL',
                    link: edital19,
                },
                {
                    text: 'RESULTADO FINAL DOS APROVADOS',
                    link: edital15Aprovados,
                },
                {
                    text: 'RESULTADO FINAL PRELIMINAR',
                    link: edital14,
                },
                {
                    text: `ERRATA - CRONOGRAMA`,
                    link: edital13
                },
                {
                    text: `PRORROGAÇÃO DAS INSCRIÇÕES`,
                    link: edital11
                },
                {
                    text: `RESULTADO FINAL`,
                    link: renderFinal
                },
                {
                    text: `RESULTADO FINAL PRELIMINAR`,
                    link: renderFinalPreliminar
                },
                {
                    text: `Chamada pública para complementação de documentos - 21/01/2022`,
                    link: renderChamado
                },
                {
                    text: `Edital preliminar dos aprovados - 21/01/2022`,
                    link: renderPreliminar
                },
                {
                    text: `Ver PDF do edital complementar - 30/12/2021`,
                    link: renderAdditionalPdf
                },
                {
                    text: `Errata - 06/01/2022`,
                    link: renderErrataPdf
                },
                {
                    text: `Ver Edital`,
                    link: renderPdf
                },
            ],
            formLink: `https://docs.google.com/forms/d/e/1FAIpQLScpHy8QgagNotCczhsZgI-vcZhfH8l3r5iTblaE3SKO-ZHVpw/viewform?usp=sf_link`,
            observation_message: `Após se inscrever a um curso é necessário o preenchimento de um formulário para finalizar sua inscrição.`
        },
        {
            title: <>
                <div>
                    Os cursos objeto do presente processo seletivo, oferecidos pela Secretaria de Estado do Desenvolvimento Econômico - SEDEC, em parceria com o Instituto Mix, são de iniciação profissional na modalidade presencial.
                </div>
                <div>
                    <strong>Cursos -</strong>
                    <span> Intensivo de atendente de Farmácia,</span>
                    <span> Excel Básico,</span>
                    <span> Profissional e Pessoal.</span>
                </div>
                <div>
                    <strong>Municípios - </strong><span>Porto Velho/RO</span>
                </div>
            </>,
            subtitle: `Encerrado - EDITAL Nº 20/2022/SEDEC-SINE`,
            route: '20-2022-sedi',
            editais: [{
                text: 'RESULTADO FINAL',
                link: editalrf202022
            },{
                text:`EDITAL Nº 20/2022/SEDEC-SINE`,
                link: edital2020221
            }],
            formLink: `https://docs.google.com/forms/d/e/1FAIpQLScpHy8QgagNotCczhsZgI-vcZhfH8l3r5iTblaE3SKO-ZHVpw/viewform?usp=sf_link`,
            observation_message: `Após se inscrever a um curso é necessário o preenchimento de um formulário para finalizar sua inscrição.`
        },
        {
            title: <span> O Governo de Rondônia, por meio da Secretaria de Estado de Desenvolvimento Econômico
        (SEDEC), através da Coordenadoria Geral de Trabalho, Emprego e Renda em parceria com o
        IDEP- Instituto Estadual de Desenvolvimento da Educação Profissional, vem informar a lista de aprovados
        para o curso de Gestão de Vendas, que será realizado na modalidade online. <br/>
        <strong>Curso </strong> Gestão De Vendas <br/></span>,
            subtitle: `Encerrado - EDITAL Nº 15/2022/SEDEC-SINE`,
            route: '15-2022-sedec',
            editais: [
                {
                    text:`LISTA DE APROVADOS  `,
                    link: edital15
                },
            ],
            formLink: null,
            observation_message: null
        },
        {
            title: <span> A Secretaria de Estado do Desenvolvimento Econômico - SEDEC, no uso de suas atribuições legais, torna
        público, o processo seletivo para os cursos de Capacitação profissional, conforme descriminados nos itens
        deste Edital. <br/>
        <strong>Cursos - </strong> Manicure, Depilação e Designer de Sobrancelhas <br/>
        <strong>Municípios - </strong> Porto Velho e Cacoal</span>,
            subtitle: `Encerrado - EDITAL Nº 8/2022/SEDEC-SINE`,
            route:'8-2022-sedec',
            editais: [{
                text:`EDITAL Nº 8/2022/SEDEC-SINE - 07/03/2022 `,
                link: edital8
            },
                {
                    text:`RESULTADO FINAL `,
                    link: edital9
                },
                {
                    text:`CLASSIFICAÇÃO FINAL `,
                    link: edital12
                },
            ],
            formLink: `https://docs.google.com/forms/d/e/1FAIpQLSf7qw8SGYAEkCpwNpjDLtUcCHksQyHUD_ieMz76QpTta7XiRw/viewform?usp=sf_link`,
            observation_message: `Após se inscrever a um curso é necessário o preenchimento de um formulário para finalizar sua inscrição.`
        },
        {
            title: <span> A Secretaria de Estado do Desenvolvimento Econômico - SEDEC, no uso de suas atribuições legais, torna
        público, o processo seletivo para os cursos de Capacitação profissional, conforme descriminados nos itens
        deste Edital. <br/>
        <strong>Cursos - </strong> Atendente de Farmácia, Auxiliar de Veterinário e pet Shop <br/>
        <strong>Município - </strong> Cacoal</span>,
            subtitle: `Encerrado - EDITAL Nº 7/2022/SEDEC-SINE`,
            route: '7-2022-sedec',
            editais: [{
                text:`EDITAL Nº 7/2022/SEDEC-SINE - 07/03/2022 `,
                link: edital7
            },
                {
                    text:`INFORMATIVO `,
                    link: edital10
                },
            ],
            formLink: `https://docs.google.com/forms/d/e/1FAIpQLSdX1s7Ba9jQpKuRUU939O-UeyxtN4Yh1qRxLXCzJPvxm9ESOA/viewform?usp=sf_link`,
            observation_message: `Após se inscrever a um curso é necessário o preenchimento de um formulário para finalizar sua inscrição.`
        },
        {
            title: `A Secretaria de Estado do Desenvolvimento Econômico - SEDEC, no uso de suas atribuições legais, torna
        público, o processo seletivo para os cursos de Capacitação profissional, conforme descriminados nos itens
        deste Edital.`,
            subtitle: `Encerrado - EDITAL Nº 5/2022/SEDEC-SINE`,
            route: '5-2022-sedec',
            editais: [
                {
                    text:`EDITAL Nº 5/2022/SEDEC-SINE - 25/02/2022 `,
                    link: edital1
                },
                {
                    text:`Resultado Final`,
                    link: edital5fim
                },
            ],
            formLink: `https://docs.google.com/forms/d/e/1FAIpQLSdBX-XIlQogRfYF72yB4yL_0ZcKplgtlxOAKuQK2nFTAQZClw/viewform?usp=sf_link`,
            observation_message: `Após se inscrever a um curso é necessário o preenchimento de um formulário para finalizar sua inscrição.`
        },
    ]
}
