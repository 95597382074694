import React, {useEffect, useState} from 'react';
import HomeMainComponent from "../Components/PageComponents/Public/HomePage/HomeMainComponent";
import BannerApps from "../Components/PageComponents/Public/HomePage/BannerAps";
import HowToComponent from "../Components/PageComponents/Public/HomePage/HowToComponent";
import CounterComponent from "../Components/PageComponents/Public/HomePage/CounterComponent";
import CreateAccountBanner from "../Components/PageComponents/Public/HomePage/CreateAccountBanner";
import Loading from "../Components/Generic/Loading";
import useAuth from "../Hooks/Auth";
import HowToRecruit from "../Components/PageComponents/Public/HowItWorksPage/HowToRecruit";
import PartnersComponent from "../Components/PageComponents/Public/HomePage/PartnersComponent";
import HowToHireServices from "../Components/PageComponents/Public/HowItWorksPage/HowToHireServices";
import AboutUsHomeComponent from "../Components/PageComponents/Public/HomePage/AboutUsHomeComponent";
import Button from "antd/es/button";
import useApi from "../Hooks/Api";
import {useSelector} from "react-redux";
import IdealCollaboratorCard from "../Components/PageComponents/Public/HomePage/IdealCollaboratorCard";
import ServiceProvidersCard from "../Components/PageComponents/Public/HomePage/ServiceProvidersCard";
import TestimonialComponent from "../Components/PageComponents/Public/HomePage/TestimonialComponent";
import CourseInformation from "../Components/PageComponents/Public/HomePage/CourseInformation";
import VacanciesOfTheDay from "../Components/PageComponents/Public/HomePage/VacanciesOfTheDay";



const HomePage = props => {

    const [loading, setLoading] = useState(true);
    const {isLogged} = useAuth();
    const [moduleSelected, setModuleSelected] = useState('-1');
    const handleLoading = () => {
        setTimeout(() => {
            setLoading(false);
        },[500]);
    };

    useEffect(() => {
        window.scrollToElement('body');
        handleLoading();
    },[]);

    return (
        <div>
            <div id={'mainComponentId'} style={{opacity: loading? 0: 1, transition: '500ms', background: '#f8f9fc'}}>
                <HomeMainComponent setModule={(item) => setModuleSelected(item)}/>
                <AboutUsHomeComponent/>
                {/*<PartnersComponent/>
                {(moduleSelected === '-1' || moduleSelected === 'jobs') && <HowToRecruit/>}
                {(moduleSelected === '-1' || moduleSelected === 'crafty') && <HowToHireServices/>}*/}
                {!isLogged() && <CreateAccountBanner/> }
                <CounterComponent/>
                <IdealCollaboratorCard/>
                <VacanciesOfTheDay/>
                <CourseInformation/>
                <BannerApps/>
            </div>
        </div>
    );
};

HomePage.propTypes = {};

export default HomePage;
