import React, {useState,useEffect}    from 'react';
import Icon from "antd/es/icon";
import {Images} from "../Helpers/Images";
import {Col, Row} from "antd";
import {editais} from "../ApplicationAssets/editaisConfig";
import {useHistory} from "react-router-dom";
import useLocalStorage from "../Hooks/Storage";
import {StorageVariables} from "../Helpers/StoragesVariables";
const PDFReader = props => {
    const [editalConfig, setEditalConfig] = useState(editais);
    const [activeEdital, setActiveEdital] = useState(editais.active);

    let history = useHistory()
    let storage = useLocalStorage(StorageVariables.routeEdital)

    const getData = () => {
        let valueStorage = storage.getPrimitive(StorageVariables.routeEdital)

        if (props.match.params.id === undefined && !valueStorage) {
            getActive(editais.active.route, editais.active)

        } else if (valueStorage) {
            let completed = editalConfig.completed
            let onGoing = editalConfig.ongoing

            for (let i = 0 ; i < completed.length ; i ++) {
                if (completed[i].route === valueStorage) {
                    let active = completed[i]
                    let route = completed[i].route

                    return getActive(route, active)
                }
            }
            for (let i = 0 ; i < onGoing.length ; i ++) {
                if (onGoing[i].route === valueStorage) {
                    let active = onGoing[i]
                    let route = onGoing[i].route

                    return getActive(route, active)
                }
            }
        }
    }

    const getActive = (route, active) => {
        history.push(`/editais/${route}`)
        setActiveEdital(active)
    }

    const handleEdital = (i, route) => {
        setActiveEdital(i)
        editais.active.route = route

        let filterStringUrl = editais.active.route.toLowerCase().replace(/\s+/g, '-')
        history.push(`/editais/${filterStringUrl}`)
        storage.setPrimitive(filterStringUrl)
    }


    useEffect(() => {
        getData()
        window.scrollToElement('body');
    },[]);

    return (
        <div style={{display: `flex`, justifyContent: `center`}}>
            <Col xs={23} lg={22} xl={18} xxl={15} style={{textAlign: 'center', justifyContent: 'space-around', paddingTop: 40}}>
                <div style={{marginBottom: 40}}>
                    <h1 style={{fontSize: 30}}>Processos seletivos e editais</h1>
                </div>
                <Row gutter={16} style={{width: `100%`}}>
                    <Col sm={24} md={16} style={{marginBottom: 20}}>
                        <div style={{border: `1px solid gainsboro`, borderRadius: 5}}>
                            <div style={{padding: 5, borderBottom: `1px solid gainsboro`}}>
                                <h3 style={{fontWeight: "bold", margin: 0, color: `#1a5697`}}> {activeEdital.subtitle} </h3>
                            </div>
                            <img src={Images.edital2} style={{height: 200, marginTop: 20, marginBottom: 20, maxWidth: '100%'}}/>

                            <div style={{display: `flex`, justifyContent: `center`, marginTop: 10, paddingBottom: 10}}>
                                <div style={{padding: 5, maxWidth: 500}}>
                                    <span style={{fontSize: 15, margin: 0}}> {activeEdital.title} </span>
                                </div>
                            </div>
                            <div>
                                <h2 style={{fontSize: 12, color: "gray", textAlign: 'center'}}> {activeEdital.observation_message} </h2>
                            </div>

                            <Row style={{padding: 20, borderTop: `1px solid #f3f3f3`}}>
                                <Col xs={24} md={24} style={{display: "flex", textAlign: "center", flexFlow: "column"}}>
                                    {activeEdital.editais.map((item) =>
                                            <a style={{marginTop: 20}} href={item.link} target = "_blank">
                                                <Icon style={{color: `#497db7`, marginRight: 10}} type="file-pdf" />
                                                <span style={{color: `#497db7`}}>
                                        {item.text}
                                    </span>
                                            </a>
                                    )}
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col sm={24} md={8}>
                        <div style={{border: `1px solid gainsboro`, borderRadius: 5, marginBottom: 20, color: `#939393`}}>
                            <div style={{padding: 5, borderBottom: `1px solid gainsboro`, marginBottom: 10}}>
                                <h3 style={{fontWeight: "bold", margin: 0, color: `rgb(38 38 38)`}}> Em andamento </h3>
                            </div>
                            {editalConfig.ongoing.map((item) => {
                                    return (
                                        <div style={{padding: 4, fontWeight: activeEdital.subtitle === item.subtitle&& `bold`, color: activeEdital.subtitle === item.subtitle&& `#1a5697`}} className={'limit-rows clamp-1'}>
                                            <a onClick={() => handleEdital(item, item.route)}>
                                                {item.subtitle}
                                            </a>
                                        </div>
                                    )
                                }
                            )}
                        </div>

                        <div style={{border: `1px solid gainsboro`, borderRadius: 5, color: `#939393`}}>
                            <div style={{padding: 5, borderBottom: `1px solid gainsboro`, marginBottom: 10}}>
                                <h3 style={{fontWeight: "bold", margin: 0, color: `#aaa`}}> Encerrados </h3>
                            </div>
                            {editalConfig.completed.map((item) =>
                                <div style={{padding: 5, fontWeight: activeEdital.subtitle === item.subtitle&& `bold`, color: activeEdital.subtitle === item.subtitle&& `#1a5697`}}>
                                    <a onClick={() => handleEdital(item, item.route)}>
                                        {item.subtitle}
                                    </a>
                                </div>
                            )}
                        </div>
                    </Col>
                </Row>
            </Col>
        </div>
    );
};

export default PDFReader;
