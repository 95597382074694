import React from "react";
import {Icon} from "antd";
import {Env} from "./Env";

export const Texts = {
    popover_client_panel: 'Clique aqui para ver o seu painel de contratante.',
    popover_worker_panel: 'Clique aqui para ver o seu painel de trabalhador.',
    auth_page: {
        login_title: <span> <strong> Seja bem-vindo(a) </strong> ao {Env.application_title} </span>,
        login_subTitle: 'Cadastre-se e faça parte!',
        register_enterprise_title: <span> <strong> Vamos cadastrar sua empresa? </strong> </span>,
        register_enterprise_subTitle: 'Cadastre sua empresa para divulgar vagas em todo o estado',
        register_enterprise_end:  <span> <strong> Empresa cadastrada com sucesso! </strong> </span> ,
        reg_title: <> <div className={'numbers'}> 1 </div> <span> Informe o número do seu celular </span> </>,
        reg_subTitle: 'Para maior segurança é necessário validar o número de seu celular',
        reg_confirm: <> <div className={'numbers'}> 2 </div> <span> Confirme seu número </span> </>,
        reg_subConfirm: 'Insira novamente o número digitado anteriormente',
        reg_number: <> <div className={'numbers'}> 2 </div> <span> Um código foi enviado para seu número! </span> </>,
        reg_subNumber: 'Insira ao lado o código recebido por SMS em seu celular',
        reg_form: <> <div className={'numbers'}> 3 </div> <span> Complete as informações </span> </>,
        reg_subForm: 'Insira as informações necessárias ao lado para completar seu cadastro',
        reg_end: <> <div className={'numbers'}> <Icon type={'check'}/> </div> <span> Tudo certo! </span> </>,
        reg_subEnd: 'Registro concluído! agora você pode  utilizar todos os nossos serviços!' ,
        forgot: <span> Insira seu  <strong> CPF e EMAIL </strong> </span>,
        subForgot: 'Você receberá um email com as informações necessárias '

    },
    worker_home_page: {
        howTo: {
            title1: 'Como Funciona',
            subTitle1:  <span>O {Env.application_title} possui uma base de dados com milhares de<strong className={'primary-sub-color'}> prestadores de serviços </strong> nos vários cantos do {Env.state_restriction_full}! Busque e solicite qualquer serviço, a qualquer hora, em qualquer lugar<br/> <br/></span>,
            card_title1: 'Cadastre-se',
            card_subTitle1: `Baixe agora o app ${Env.application_title} para Trabalhadores ou cadastre-se no site. É rápido e gratuito!`,
            link1: '/como-funciona/contratar',
            card_title2: 'Preencha seu currículo',
            card_subTitle2: 'Capriche no preenchimento de seu currículo. Cadastre suas experiências, pretensões, cursos realizados e muitos mais! É a hora de você mostrar aos empregadores suas características e conhecimento.',
            link2: '/como-funciona/curriculo',
            card_title3: 'Mantenha telefone e e-mail sempre atualizados em seu cadastro',
            card_subTitle3: 'Os empregadores interessados em seu currículo entrarão em contato diretamente com você a qualquer momento.',
            link3: '/como-funciona/estabelecimentos',
        },
    },
    home_page: {
        main: {
            title1: `Bem-vindo ao ${Env.application_title}`,
            subTitle1: 'Como posso te ajudar hoje? \n',
            card_crafty_title: 'Quero contratar prestador de serviço',
            card_crafty_sub: 'Mais de 100 categorias de profissionais para você',
            card_jobs_title: 'Quero contratar funcionário',
            card_jobs_sub: 'Currículos atualizados, geolocalizados e categorizados com filtros de pesquisa próximos a você',
            card_vacancy_Title: 'Quero divulgar uma vaga',
            card_vacancy_sub: 'Quero informar uma disponibilidade de vaga em minha empresa',
            card_club_title: 'Quero descontos',
            card_club_sub: 'Quero obter cupoms de desconto nas lojas mais próximas de mim',
            crafty_title: 'Encontre profissionais de confiança perto de você',
            crafty_subTitle: `Milhares de prestadores de serviço utilizam o ${Env.application_title}.`,
            jobs_title: 'Encontre profissionais disponíveis mais próximos de você',
            jobs_subTitle: <span>Você também pode divulgar uma vaga em nossa plataforma,<span style={{cursor: 'pointer'}} className={'primary-color'}> cadastre-se</span> </span>,
            club_title: 'Encontre estabelecimentos de confiança perto de você',
            club_subTitle: `${Env.application_title}  é ambiente de divulgação de mais de 100,000 estabelecimentos.`,
        },
        howTo: {
            title1: `O que é o ${Env.application_title}`,
            subTitle1:  <span> O {Env.application_title} é uma iniciativa do Governo do Estado de {Env.state_restriction_full}, juntamente com a {Env.support_company}, para fomento à empregabilidade, encurtando as distâncias entre empregadores e trabalhadores {Env.citizen_name}. <br/> <br/></span>,
            title2: 'Como funciona?',
            card_title1: 'Quer contratar um serviço?',
            card_subTitle1: 'Precisa de uma diarista? um advogado? jardineiro? ou até mesmo de um segurança?',
            link1: '/como-funciona/contratar',
            card_title2: 'Quer contratar um funcionário? ',
            card_subTitle2: 'Gostaria de ter acesso a mais de 12 zilhões de currículos do SINE? e encontrar currículos com poucos cliques e de fácil acesso? ',
            link2: '/como-funciona/curriculo',
            card_title3: 'Quer ter acesso a descontos e promoções nos estabelecimentos de sua cidade?',
            card_subTitle3: 'O craftdy possui uma base de mais de 90 mil estabelecimentos cadastrados. ',
            link3: '/como-funciona/estabelecimentos',
        },
        counter: {
            title1: 'CURRÍCULOS',
            value1: '100,000',
            title2: 'EMPRESAS',
            value2: '170',
            title3: 'VAGAS JÁ DISPONIBILIZADAS',
            value3: '500,000',
            title4: 'VAGAS DISPONIBILIZADAS',
            value4: '12,000,000',
        },
        register: {
            title1: 'Comece Agora',
            title2: 'Passo a passo para encontrar o colaborador ideal',
            title3: 'Quer contratar prestadores de serviço?',
            title4: 'Feedbacks de profissionais e empresários',
            subTitle1: `${Env.application_title}, a maior rede profissional do Estado de ${Env.state_restriction}`,
            subTitle2: `O ${Env.application_title} possui em sua base de dados milhares de prestadores de serviços por todo o Estado de ${Env.state_restriction}. Encontre profissionais, solicite orçamentos e contrate qualquer serviço, a qualquer hora e em qualquer lugar.`,
            client_title: 'Busque por profissionais mais próximos de você',
            client_list:   <>
                <span style={{marginBottom: 10}}> <Icon className={'primary-sub-color'} type={'check'}/> Maior base PcD do estado </span>
                <span style={{marginBottom: 10}}> <Icon className={'primary-sub-color'} type={'check'}/> São milhares de profissionais em busca de uma nova colocação </span>
                <span> <Icon className={'primary-sub-color'} type={'check'}/> 100% gratuito! </span>
            </>,
            worker_title: `Em busca de uma nova colocação? ${Env.application_title} é a melhor opção!`,
            worker_list:  <>
                <span style={{marginBottom: 10}}> <Icon type={'check'}/> Cadastre seu currículo completo para ser encontrado pelos empregadores do estado </span>
                <span style={{marginBottom: 10}}> <Icon type={'check'}/> Amplie suas possibilidades de contratação gratuitamente </span>
                <span > <Icon type={'check'}/> Pelo app ou nosso site, acesso agora mesmo!  </span>
            </>,

            divulge_vacancy_title: 'Divulgue Vagas',
            divulge_vacancy_list:<>
                <span style={{marginBottom: 10}}> <Icon className={'primary-sub-color'} type={'check'}/> Cadastre sua empresa para divulgar vagas. </span>
                <span style={{marginBottom: 10}}> <Icon className={'primary-sub-color'} type={'check'}/> Suas vagas divulgadas serão exibidas para trabalhadores de todo {Env.state_restriction} no aplicativo. </span>
                <span> <Icon className={'primary-sub-color'} type={'check'}/> Divulgue oportunidade de vagas em seu negócio, insira imagem, vídeo, informações que acha pertinente para o preenchimento da vaga e muito mais! </span>
            </>,

            curriculos_title: 'Busque e analise currículos',
            curriculos_list: <>
                <span style={{marginBottom: 10}}> <Icon className={'primary-sub-color'} type={'check'}/> Faça sua busca por mais de 7000 ocupações diferentes. </span>
                <span style={{marginBottom: 10}}> <Icon className={'primary-sub-color'} type={'check'}/> Utilize mais de 15 filtros de qualificação para facilitar sua busca. </span>
                <span> <Icon className={'primary-sub-color'} type={'check'}/> Veja o currículo dos trabalhadores que se candidataram às suas vagas. Escolha quantos currículos desejar para agendar uma entrevista. </span>
            </>,

            feedback_title: 'Entre em contato e nos dê o feedback',
            feedback_list: <>
                <span style={{marginBottom: 10}}> <Icon className={'primary-sub-color'} type={'check'}/> Após o processo seletivo, compartilhe sua experiência conosco. </span>
                <span style={{marginBottom: 10}}> <Icon className={'primary-sub-color'} type={'check'}/> Nos avisar das contratações realizadas ou não é muito fácil e rápido, 100% digital. </span>
                <span> <Icon className={'primary-sub-color'} type={'check'}/> Seu feedback é muito importante para nós. Assim, mantemos uma base sempre atualizada. </span>
            </>,

            service_providers_card01: <>
                <span> <Icon className={'secondary-color'} type={'check'}/> Através de seu Smartphone, com nosso aplicativo, ou em seu computador, através de seu navegador de internet preferido. </span>
            </>,
            service_providers_card02: <>
                <span style={{marginBottom: 10}}> <Icon className={'secondary-color'} type={'check'}/> Insira o endereço para a prestação do serviço que localizaremos os prestadores mais próximos a você. </span>
                <span> <Icon className={'secondary-color'} type={'check'}/> São mais de 100 categorias de serviço para você encontrar o profissional que precisa (Ex. Diaristas, encanadores, eletricistas, advogados, animadores de festas e muito mais). </span>
            </>,
            service_providers_card03: <>
                <span style={{marginBottom: 10}}> <Icon className={'secondary-color'} type={'check'}/> Entre em contato direto com o whatsapp do prestador </span>
                <span style={{marginBottom: 10}}> <Icon className={'secondary-color'} type={'check'}/> Solicite seu orçamento e negocie diretamente, sem intermediários. </span>
            </>,
            service_providers_card05: <>
                <span style={{marginBottom: 10}}> <Icon className={'secondary-color'} type={'check'}/> Após negociar com o prestador, não se esqueça de nós informar se houve a prestação de serviço. </span>
                <span style={{marginBottom: 10}}> <Icon className={'secondary-color'} type={'check'}/> Se houve a prestação de serviço não se esqueça de avaliar o prestador! As notas são muito importantes para representar sua satisfação com o serviço prestado e recomendar o profissional à outras pessoas. </span>
            </>,
        },
        app: {
            title1: 'Baixe também nossos apps!',
            subTitle1: `Se você procura um novo colaborador, baixe o ${Env.application_title}. Mas se você quer cadastrar seu currículo em busca de uma nova oportunidade baixe o ${Env.application_title} para Trabalhadores`,
        },
    },
    hireServicesBanner: {
        title1: 'Encontre prestadores de serviços de forma fácil e segura!',
        subTitle1: `Com o ${Env.application_title} você encontra prestadores de serviços, pertinho de você`,
        title2: 'Da comodidade de sua casa ou trabalho, solicite orçamentos para os serviços que você precisa.',
        subTitle2: `Escolha o melhor profissional para suas necessidades e contribua com a geração de renda dos trabalhadores ${Env.citizen_name}\n`,
        title3: `Leve o ${Env.application_title} juntinho de você!`,
        subTitle3: `Utilize também os aplicativos IOS ou Android e tenha o ${Env.application_title} sempre em mãos.`,
    },
    searchEstablishmentsBanner: {
        title1: 'Estabelecimentos com Ofertas? e de Confiança?',
        subTitle1: `É facil! Use o ${Env.application_title} e encontre diversas lojas perto de você!.`,
        title2: 'Leve o crafty juntinho de você!',
        subTitle2: 'Utilize também os aplicativos para IOS ou Android e tenha o crafty sempre à mão.',
    },
    howItWorksCrafty: {
        mainTitle: 'Quer contratar prestadores de serviço?',
        mainText: <div style={{maxWidth: 600, margin: 'auto'}}> <span>
        O {Env.application_title} possui em sua base de dados milhares de <strong> prestadores de
            serviços </strong> por todo o Estado de {Env.state_restriction}. Encontre profissionais, solicite orçamentos e
        contrate qualquer serviço, a qualquer hora e em qualquer lugar.
            </span>
        </div>,
        step1Title: ' Escolha a melhor plataforma para você utilizar',
        step1: <ul style={{fontSize: 16}}>
            <li> <span> Através de seu Smartphone, com nosso aplicativo, ou em seu computador, através de seu navegador de internet preferido. </span></li>
        </ul>,
        step2Title: 'Busque pelo serviço que deseja',
        step2: <ul style={{fontSize: 16}}>
            <li> <span> Insira o endereço para a prestação do serviço que localizaremos os prestadores mais próximos a você;  </span></li>
            <li> <span> São mais de 100 categorias de serviço para você encontrar o profissional que precisa (Ex. Diaristas, encanadores, eletricistas, advogados, animadores de festas e muito mais). </span></li>
        </ul>,
        step3Title: 'Combine os detalhes com o prestador',
        step3: <ul style={{fontSize: 16}}>
            <li> <span> Informe os detalhes do serviço, diga se tem urgência, quais dias da semana e períodos em que podem ser realizados os serviços e dê mais informações específicas sobre o serviço;  </span></li>
            <li> <span> Solicite seu orçamento e negocie diretamente com o prestador, sem intermediários; </span></li>
            <li> <span> Faça um agendamento e pronto! Basta aguardar a execução do seu serviço.  </span></li>
        </ul>,
        step4Title: 'Aguarde a Proposta',
        step4: <ul style={{fontSize: 16}}>
            <li> <span> Ao receber a solicitação, o próprio prestador irá te enviar uma proposta, informando o dia,
            horário e valor. Caso você esteja de acordo, é só aceitar! Agora caso não seja bem o que está buscando, você pode negociar direto com o prestador de serviço; </span></li>
            <li> <span> Disponibilizamos um chat para facilitar a comunicação entre contratante e prestador após a
            solicitação de orçamento para o serviço. </span></li>
            <li> <span> Logo que você aceitar uma proposta recebida, seu atendimento estará marcado! Nesse
momento tanto você, assim como o prestador de serviços terão acesso aos números de celular
um do outro. </span> </li>
        </ul>,
        step5Title: 'Não se esqueça de avaliar!',
        step5: <ul style={{fontSize: 16}}>
            <li> <span> Ao termino do serviço, não se esqueça de avaliar o prestador! As notas são muito importantes para representar sua satisfação com o serviço prestado e recomendar o profissional à outras pessoas. </span></li>
        </ul>

    },
    howItWorksClub: {
        mainTitle: 'Como contratar serviços?',
        mainText: <div style={{maxWidth: 600, margin: 'auto'}}> <span>
            O {Env.application_title}  possui uma base de dados com milhares de
            <strong className={'primary-sub-color'}> prestadores de serviços </strong> nos vários cantos do {Env.state_restriction_full}! Busque e solicite qualquer
            serviço, a qualquer hora, em qualquer lugar
            <br/> <br/>
            </span>
        </div>,
        step1Title: 'Cadastre-se',
        step1: <ul style={{fontSize: 16}}>
            <li> <span> Registre-se para ter acesso a todas as funcionalidades </span></li>
            <li> <span> Baixe também o App <strong> aqui. </strong> </span> </li>
            <li> <span> Gostaria de ser um Prestador? <strong> clique aqui </strong> </span> </li>
            <li> <span> Já tem uma conta? <strong> Entre aqui</strong> </span> </li>
        </ul>,
        step2Title: 'Busque por serviços',
        step2: <ul style={{fontSize: 16}}>
            <li> <span> Busque por prestadores de serviços;  </span></li>
            <li> <span> Busque por prestadores próximos de você </span></li>
            <li> <span> Compare avaliações e preços e entre em contato </span> </li>
        </ul>,
        step3Title: 'Entre em contato',
        step3: <ul style={{fontSize: 16}}>
            <li> <span> Tenha acesso ao número do prestador  </span></li>
            <li> <span> Combine um horário </span></li>
            <li> <span> Negocie preços  </span></li>
            <li> <span> Faça um agendamento </span> </li>
        </ul>,
        step4Title: 'Faça sua avaliação',
        step4: <ul style={{fontSize: 16}}>
            <li> <span> Avalie o serviço prestado  </span></li>
            <li> <span> Você também será avaliado </span></li>
            <li> <span> Caso algo de inesperado acontecer entre em <strong> contato </strong> </span> </li>
        </ul>
    },
    howItWorksSine: {
        mainTitle: 'Passo a passo para encontrar o colaborador ideal',
        mainText: <div style={{maxWidth: 600, margin: 'auto'}}> <span>
            O {Env.application_title} possui uma base de dados com milhares de
            <strong className={'primary-sub-color'}> prestadores de serviços </strong> nos vários cantos do {Env.state_restriction_full}! Busque e solicite qualquer
            serviço, a qualquer hora, em qualquer lugar
            <br/> <br/>
            </span>
        </div>,
        step1Title: 'Divulgue vagas',
        step1: <ul style={{fontSize: 16}}>
            <li> <span> Cadastre sua empresa para divulgar vagas; </span></li>
            <li> <span> Divulgue oportunidade de vagas em seu negócio, insira imagem, vídeo, informações que acha pertinente para o preenchimento da vaga e muito mais;</span></li>
            <li> <span> Suas vagas divulgadas serão exibidas para trabalhadores de todo {Env.state_restriction} no aplicativo; </span> </li>
           </ul>,
        step2Title: 'Busque e analise currículos',
        step2: <ul style={{fontSize: 16}}>
            <li> <span> Faça sua busca por mais de 7000 ocupações diferentes; </span></li>
            <li> <span> Utilize mais de 15 filtros de qualificação para facilitar sua busca;</span></li>
            <li> <span> Veja o currículo dos trabalhadores que se candidataram a suas vagas; </span> </li>
            <li> <span> Escolha quantos currículos desejar para agendar uma entrevista. </span> </li>
        </ul>,
        step3Title: 'Entre em contato e nos dê o feedback',
        step3: <ul style={{fontSize: 16}}>
            <li> <span> Após o processo seletivo compartilhe sua experiência conosco;
            Nos avisar das contratações realizadas ou não, é muito fácil e rápido, 100% digital;    </span></li>
            <li> <span> Seu feedback é muito importante para nós para que mantenhamos uma base
            sempre atualizada!
             </span></li>
        </ul>,
        step4Title: 'Faça sua avaliação',
        step4: <ul style={{fontSize: 16}}>
            <li> <span> Avalie o serviço prestado  </span></li>
            <li> <span> Você também será avaliado </span></li>
            <li> <span> Caso algo de inesperado acontecer entre em <strong> contato </strong> </span> </li>
        </ul>
    }
};
